.comment-section {
  max-width: 800px;
  margin: 0 0 0 100px;
  padding: 20px;
}

.comment {
  display: flex;
  margin-bottom: 20px;
}

.user-avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #ddd;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment-content {
  flex: 1;
  padding: 10px;
}

.username {
  font-weight: bold;
  margin: 0;
  color: #333;
}

.timestamp {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.comment-text {
  margin-top: 10px;
  color: #444;
}

.reply-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.reply-form {
  margin-top: 10px;
}

.reply-form textarea {
  width: 100%;
  height: 80px;
  padding: 5px;
  resize: vertical;
}

.reply-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media(max-width:767px){
  .comment-section {
    width: 100%;
    margin: 0;
  }
}